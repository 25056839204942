import React, { useRef, useState, useEffect } from "react";
import onWindowResize from "@/helpers/onWindowResize";
import { imageCacheSource } from "@/api/helpers";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import ImagePlaceHolder from "@/images/icons/icon-photos.svg";

import { useCallback } from "react";
import trans from "@/helpers/trans";
import { useMemo } from "react";
import useAuthStore from "@/stores/auth";
import formatDate from "@/helpers/formatDate";

function resize(container, element, transform, width, height) {
	if (container && element) {
		const { offsetWidth, offsetHeight } = container;
		const { style } = element;
		const containerAspectRatio = offsetWidth / offsetHeight;
		const mediaAspectRatio = width / height;

		style.transform = `translate(${transform.x}%,${transform.y}%) scale(${transform.z})`;
		if (mediaAspectRatio > containerAspectRatio) {
			style.width = `${Math.floor((width * offsetHeight) / height)}px`;
			style.height = `${offsetHeight}px`;
		} else {
			style.width = `${offsetWidth}px`;
			style.height = `${Math.floor((height * offsetWidth) / width)}px`;
		}
	}
}

function resizeImage(container, image, transform) {
	if (container && image) {
		const { naturalWidth, naturalHeight } = image;
		if (naturalWidth && naturalHeight) {
			// resize(container, image, transform, naturalWidth, naturalHeight);
		}
	}
}

function resizeVideo(container, video, transform) {
	if (container && video) {
		const { videoWidth, videoHeight } = video;
		if (videoWidth && videoHeight) {
			resize(container, video, transform, videoWidth, videoHeight);
		}
	}
}

function Image({ source, transform, width = null, height = null, onLoad = null, alt = null, title = null }) {
	const containerRef = useRef(null);
	const imageRef = useRef(null);
	const transformRef = useRef(transform);
	const [isLoaded, setIsLoaded] = useState(false);
	useEffect(() => void (transformRef.current = transform), [transform]);

	const picture = useMemo(() => {
		const picture = {
			imgSrc: null,
			imgAlt: alt,
			sources: [],
		};

		const pictureWidth = width ? width : containerRef?.current?.offsetWidth;
		const pictureHeight = height ? height : containerRef?.current?.offsetHeight;
		if (!pictureWidth || !pictureHeight) {
			return picture;
		}

		let pictureSource = source;
		if (pictureSource && pictureSource.startsWith("youtube/")) {
			picture.imgSrc = `https://i.ytimg.com/vi/${source.substring(8)}/hqdefault.jpg`;
		} else if (pictureSource) {
			if (pictureSource.startsWith("giphy/")) {
				const index = source.lastIndexOf(".");
				pictureSource = `${source.substring(0, index)}.gif`;
			}
			picture.imgSrc = imageCacheSource(pictureSource, {
				width: pictureWidth,
				height: pictureHeight,
				transform,
				format: "jpg",
			});
			picture.sources.push({
				srcSet: imageCacheSource(pictureSource, {
					width: pictureWidth,
					height: pictureHeight,
					transform,
					format: "webp",
				}),
				type: "image/webp",
			});
		}

		return picture;
	}, [source, alt, transform, width, height]);

	useEffect(() => {
		if (containerRef.current) {
			const resize = () => {
				if (containerRef.current && imageRef.current) {
					resizeImage(containerRef.current, imageRef.current, transformRef.current);
				}
			};

			resize(); // Initial resize

			return onWindowResize(resize);
		}
	}, []);

	const onLoadInner = useCallback(() => {
		if (containerRef.current && imageRef.current) {
			resizeImage(containerRef.current, imageRef.current, transformRef.current);
		}
		setIsLoaded(true);
		if (onLoad) {
			onLoad();
		}
	}, [onLoad]);

	const setImageRef = useCallback(
		(element) => {
			imageRef.current = element;
			if (imageRef.current && imageRef.current.complete) {
				onLoadInner();
			}
		},
		[onLoadInner]
	);

	return (
		<div ref={containerRef} className="relative top-0 left-0 flex items-center justify-center w-full h-full">
			{picture && picture.imgSrc && (
				<picture className="relative w-full h-full">
					{picture.sources.map((source, index) => (
						<source key={index} srcSet={source.srcSet} type={source.type} />
					))}
					<img
						ref={setImageRef}
						className={tailwindCascade("relative top-0 left-0 w-full h-full max-w-none opacity-0", {
							"opacity-100": isLoaded,
						})}
						src={picture.imgSrc}
						alt={picture.imgAlt || null}
						onLoad={onLoadInner}
						title={title || null}
					/>
				</picture>
			)}
		</div>
	);
}

function Video({ source, transform, width = null, height = null }) {
	const containerRef = useRef(null);
	const videoRef = useRef(null);
	const transformRef = useRef(transform);
	const [isLoaded, setIsLoaded] = useState(false);
	useEffect(() => void (transformRef.current = transform), [transform]);

	const getUrl = useCallback((source, transform, width, height) => {
		if (source.startsWith("giphy/")) {
			const index = source.lastIndexOf(".");
			return source
				? imageCacheSource(`${source.substring(0, index)}.mp4`, {
						width: width,
						height: height,
						transform,
				  })
				: null;
		}

		return null;
	}, []);

	const [url, setUrl] = useState(width && height ? getUrl(source, transform, width, height) : null);

	useEffect(() => {
		let url = null;
		if (containerRef.current) {
			const offsetWidth = width || containerRef.current.offsetWidth;
			const offsetHeight = height || containerRef.current.offsetHeight;
			url = getUrl(source, transform, offsetWidth, offsetHeight);
		}
		setUrl(url);
	}, [getUrl, source, transform, width, height]);

	useEffect(() => {
		if (containerRef.current) {
			const resize = () => void resizeVideo(containerRef.current, videoRef.current, transformRef.current);

			resize(); // Initial resize

			return onWindowResize(resize);
		}
	}, []);

	const onLoadedMetadata = useCallback(() => {
		resizeVideo(containerRef.current, videoRef.current, transformRef.current);
		setIsLoaded(true);
	}, []);

	const setVideoRef = useCallback(
		(element) => {
			videoRef.current = element;
			if (videoRef.current && videoRef.current.readyState > 0) {
				// HAVE_METADATA
				onLoadedMetadata();
			}
		},
		[onLoadedMetadata]
	);

	let poster = null;
	if (url && url.indexOf("/giphy/") !== -1) {
		let giphyUrl = url;
		if (giphyUrl.indexOf("?") !== -1) {
			giphyUrl = giphyUrl.split("?")[0]; // Remove query string
		}
		let extension = giphyUrl.substring(giphyUrl.length - 4, giphyUrl.length);
		if (extension === ".mp4") {
			poster = `${giphyUrl.substring(0, giphyUrl.length - 4)}.gif`;
		}
	}

	return (
		<div ref={containerRef} className="relative top-0 left-0 flex items-center justify-center w-full h-full">
			<video
				ref={setVideoRef}
				className={`relative top-0 left-0 w-full h-full max-w-none ${isLoaded ? "opacity-100" : "opacity-0"}`}
				poster={poster}
				autoPlay={true}
				loop={true}
				muted={true}
				src={url}
				onLoadedMetadata={onLoadedMetadata}
				playsInline={true}
			/>
		</div>
	);
}

export default function MediaView({
	media,
	forceStill,
	alt = null,
	width = null,
	height = null,
	className,
	createdAt = null,
	children,
	...props
}) {
	const [transform, setTransform] = useState(media?.transform);
	useEffect(() => void setTransform(media?.transform), [media]);

	const adminFeaturesEnabled = useAuthStore((state) => state.adminFeaturesEnabled);

	const isVideo = useMemo(
		() => media?.source && media?.source.startsWith("giphy/") && !forceStill,
		[forceStill, media]
	);
	const [isLoaded, setIsLoaded] = useState(isVideo);

	const [date, setDate] = useState(formatDate(createdAt, true));
	useEffect(() => void setDate(formatDate(createdAt, true)), [createdAt]);

	const onLoad = useCallback(() => void setIsLoaded(true), []);

	return (
		<div
			className={tailwindCascade(
				"pb-4/3 relative h-0 overflow-hidden bg-black bg-opacity-0 rounded-lg z-1",
				{
					"bg-opacity-10": !isLoaded || !media?.source,
				},
				className
			)}
			{...props}
		>
			{date && (
				<div className="text-opacity-90 bg-opacity-70 rounded-br-md z-5 absolute left-0 top-0 pl-2 pr-2 md:pr-3 py-1.5 leading-none text-sm font-bold text-white bg-black">
					✓ {trans("Played %s", date)}
				</div>
			)}

			<div className="absolute inset-0 p-0">
				<div className="flex items-center justify-center w-full h-full overflow-hidden">
					{media?.source && isVideo && (
						<Video source={media.source} transform={transform} width={width} height={height} />
					)}
					{media?.source && !isVideo && (
						<div
							className={tailwindCascade("w-full h-full", {
								"opacity-0": !isLoaded,
							})}
						>
							<Image
								source={media.source}
								transform={transform}
								width={width}
								height={height}
								onLoad={onLoad}
								alt={alt}
								title={adminFeaturesEnabled && media.generated ? media.generateQuery : null}
							/>
						</div>
					)}
					{!media?.source && (
						<div className="flex items-center justify-center w-full h-full">
							<ImagePlaceHolder className="opacity-20 w-24 h-24 text-black" />
						</div>
					)}
				</div>
			</div>

			{children && (
				<div className="z-3 absolute inset-0 pointer-events-none">
					<div className="relative w-full h-full">{children}</div>
				</div>
			)}
		</div>
	);
}
