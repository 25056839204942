import React, { useEffect, useState, useMemo } from "react";
import isString from "lodash/isString";
import cloneDeep from "lodash/cloneDeep";
import isFinite from "lodash/isFinite";
import orderBy from "lodash/orderBy";

import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { getAnswerTime } from "@/helpers/answerTimes";
import { getDifficulty } from "@/helpers/difficulty";
import { deserialize } from "@/helpers/map";

import CheckIcon from "@/images/icons/check.svg";
import CrossIcon from "@/images/icons/cross.svg";
import YoutubeLogo from "@/images/logo/logo-youtube.svg";

import CoverImage from "@/components/CoverImage";

import {
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	voiceNameToLanguageCode,
} from "@/app-constants.mjs";
import {
	GOOGLE_MAPS_API_KEY,
	GOOGLE_MAPS_MAP_IDS,
	MAP_TYPE_REGULAR,
	REVEAL_TYPE_BLUR,
	REVEAL_TYPE_GRID_3X4,
	REVEAL_TYPE_GRID_3X4_SEQUENTIAL,
	REVEAL_TYPE_GRID_6X8,
	REVEAL_TYPE_GRID_6X8_SEQUENTIAL,
	REVEAL_TYPE_IRIS,
	REVEAL_TYPE_TIMELAPSE,
	REVEAL_TYPE_TIMELAPSE_RANDOM,
	REVEAL_TYPE_TIMELAPSE_REVERSE,
	REVEAL_TYPE_ZOOM_20X,
} from "@/constants";

import geoCentroids from "@/geo/geoCentroids.json";
import geoNames from "@/geo/geoNames.json";
import { upperFirst } from "@/helpers/text";
import { getPinpointPathString, getPinpointPathsFromString } from "../pages/edit/PinpointSlideEditor";

function getRevealText(revealType) {
	if (revealType === REVEAL_TYPE_GRID_3X4) {
		return trans("12 random squares");
	}
	if (revealType === REVEAL_TYPE_GRID_6X8) {
		return trans("48 random squares");
	}
	if (revealType === REVEAL_TYPE_TIMELAPSE) {
		return trans("Time-lapse");
	}
	if (revealType === REVEAL_TYPE_TIMELAPSE_REVERSE) {
		return trans("Reverse time-lapse");
	}
	if (revealType === REVEAL_TYPE_TIMELAPSE_RANDOM) {
		return trans("Random time-lapse");
	}
	if (revealType === REVEAL_TYPE_ZOOM_20X) {
		return trans("Zoom reveal");
	}
	if (revealType === REVEAL_TYPE_IRIS) {
		return trans("Iris reveal");
	}
	if (revealType === REVEAL_TYPE_BLUR) {
		return trans("Blur reveal");
	}
	if (revealType === REVEAL_TYPE_GRID_3X4_SEQUENTIAL) {
		return trans("12 sequential squares");
	}
	if (revealType === REVEAL_TYPE_GRID_6X8_SEQUENTIAL) {
		return trans("48 sequential squares");
	}
	return null;
}

const SVG_WIDTH = 400;
const SVG_HEIGHT = 300;

export default function ClassicPreview({
	index,
	showAnswers,
	className,
	blurQuestion,
	blurAnswers,
	slide,
	isOwner,
	onClick,
	theme = "light",
	padMedia,
	...props
}) {
	const [noAnswer, setNoAnswer] = useState(false);

	const [mapProps, setMapProps] = useState({ position: null, type: "easy", zoom: 9 });

	const Wrapper = isOwner && onClick ? "button" : "div";

	const slideWithSortedAnswers = useMemo(() => {
		if (slide) {
			const slideWithSortedAnswers = cloneDeep(slide);
			if (slideWithSortedAnswers.answers) {
				if (
					slideWithSortedAnswers.type !== SLIDE_TYPE_LOCATION &&
					slideWithSortedAnswers.type !== SLIDE_TYPE_REORDER
				) {
					slideWithSortedAnswers.answers = orderBy(
						slideWithSortedAnswers.answers,
						["isCorrect", "text"],
						["desc", "asc"]
					);
				}
				return slideWithSortedAnswers;
			}
		}

		return null;
	}, [slide]);

	const pathString = useMemo(
		() => slide.type === SLIDE_TYPE_PINPOINT && getPinpointPathString(slide.answers),
		[slide.answers, slide.type]
	);

	const pinpointPaths = useMemo(
		() =>
			getPinpointPathsFromString({
				pathString,
				merge: true,
				svgWidth: SVG_WIDTH,
				svgHeight: SVG_HEIGHT,
			}),
		[pathString]
	);

	useEffect(() => {
		let noAnswer = true;
		if (slideWithSortedAnswers.type !== SLIDE_TYPE_INFO_SLIDE) {
			slideWithSortedAnswers.answers.forEach((answer) => {
				if (isString(answer.text) && answer.text.length > 0) {
					noAnswer = false;
				}
			});
			setNoAnswer(noAnswer);
		}
		if (slideWithSortedAnswers.type === SLIDE_TYPE_LOCATION) {
			try {
				const { target, view } = deserialize(slideWithSortedAnswers.answers);
				const { lat, lng, placeCode } = target;
				const position = { lat, lng };

				const centroid = geoCentroids[placeCode];
				if (centroid) {
					position.lat = centroid[1];
					position.lng = centroid[0];
				}

				const name = geoNames[placeCode];

				const { type, zoom } = view;
				setMapProps({ position, type, zoom, name });
			} catch (error) {
				// console.error(error);
			}
		}
	}, [slideWithSortedAnswers.type, slideWithSortedAnswers.answers]);

	const { id: mapId, typeId: mapTypeId } = GOOGLE_MAPS_MAP_IDS[mapProps?.type || MAP_TYPE_REGULAR];

	const { difficulty, isEasy, isHard } = useMemo(
		() => getDifficulty(slideWithSortedAnswers),
		[slideWithSortedAnswers]
	);

	const lang = voiceNameToLanguageCode(slide.answerVoice);

	return (
		<Wrapper
			className={tailwindCascade(
				"bg-black-10 text-bold relative z-0 flex flex-col w-full h-full py-4 overflow-hidden text-base font-black leading-tight rounded-lg justify-start items-start text-left text-black",
				{
					"text-white": theme === "dark",
					"bg-wrong-answer-10": noAnswer && theme !== "dark",
					"bg-petrol-darkest": !noAnswer && theme === "dark",
					"bg-petrol-darkest-wrong-answer": noAnswer && theme === "dark",
				}
			)}
			onClick={onClick}
			{...props}
		>
			<div
				className={tailwindCascade(
					"z-2 bg-black-10 absolute top-0 left-0 w-12 h-12 overflow-hidden rounded-full",
					{
						"bg-wrong-answer-10": noAnswer && theme !== "dark",
						"bg-petrol-darkest": !noAnswer && theme === "dark",
						"bg-petrol-darkest-wrong-answer": noAnswer && theme === "dark",
					}
				)}
			>
				<div
					className={tailwindCascade(
						"left-1/2 top-1/2 absolute leading-none whitespace-nowrap tracking-normal transform -translate-x-1/2 -translate-y-1/2 text-2xl",
						{
							"text-lg": index + 1 >= 100,
							"text-base": index + 1 >= 1000,
						}
					)}
				>
					{index + 1}
				</div>
			</div>
			<div className="flex flex-col flex-grow-0 w-full">
				{(padMedia || slideWithSortedAnswers.media?.source) && (
					<div className="w-full px-4 mb-4">
						<div
							className={tailwindCascade("pb-4/3 relative z-0 w-full overflow-hidden rounded-lg", {
								"filter blur-sm select-none": blurAnswers,
								" bg-black-10": slideWithSortedAnswers.media?.source,
							})}
						>
							{slideWithSortedAnswers.media?.source && (
								<>
									<CoverImage
										className={tailwindCascade(
											"absolute top-0 left-0 w-full h-full overflow-hidden",
											{
												"filter blur-lg select-none": blurAnswers,
												"filter grayscale":
													slideWithSortedAnswers.type === SLIDE_TYPE_PINPOINT && !blurAnswers,
											}
										)}
										media={slideWithSortedAnswers.media}
										infoText={getRevealText(slideWithSortedAnswers.revealType)}
										alt={slideWithSortedAnswers.question}
										title={
											showAnswers && slideWithSortedAnswers.media.generated
												? slideWithSortedAnswers.media.generateQuery
												: null
										}
										width={332}
										height={249}
									/>
									{slideWithSortedAnswers.media.source.startsWith("youtube/") && (
										<div className="right-2 top-2 z-1 absolute w-2/12">
											<YoutubeLogo className="text-white" />
										</div>
									)}
									{slideWithSortedAnswers.media.generated && (
										<img
											src="/images/icons/icon-label-ai-generated.svg"
											alt={trans("AI Generated")}
											className="block z-1 w-auto bottom-[1.5%] right-[1.5%] absolute h-[8%]"
										/>
									)}
									{slideWithSortedAnswers.type === SLIDE_TYPE_PINPOINT && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
											width="100%"
											height="100%"
											className={tailwindCascade(
												"absolute inset-0 z-1 opacity-0 fill-green-light stroke-black-70",
												{
													"opacity-100": !blurAnswers,
												}
											)}
											strokeWidth={4}
											fillOpacity={0.5}
										>
											{pinpointPaths.map((d, i) => (
												<path key={i} d={d} />
											))}
											{pinpointPaths.map((d, i) => (
												<path key={i} d={d} className="stroke-green-lighter stroke-[2px]" />
											))}
										</svg>
									)}
								</>
							)}
						</div>
					</div>
				)}

				<h2
					className={tailwindCascade("mb-4 px-4", {
						"mt-10": !(slideWithSortedAnswers.media?.source || padMedia),
						"opacity-100": !slideWithSortedAnswers.question,
						"filter blur-sm opacity-50 select-none": blurQuestion,
					})}
				>
					{slideWithSortedAnswers.question || trans("(No text)")}
				</h2>

				<div className={tailwindCascade("mb-4 px-4", { "filter blur-sm opacity-50 select-none": blurAnswers })}>
					{slideWithSortedAnswers.answers &&
						!noAnswer &&
						![SLIDE_TYPE_LOCATION, SLIDE_TYPE_RANGE, SLIDE_TYPE_PINPOINT].includes(
							slideWithSortedAnswers.type
						) &&
						slideWithSortedAnswers.answers.map((ans, i) => (
							<div
								key={i}
								className={tailwindCascade(
									"flex flex-row space-x-2 space-y-1",
									"leading-tight text-base font-medium",
									{
										"text-wrong-answer": !ans.isCorrect && theme !== "dark",
										"text-wrong-answer-theme-dark": !ans.isCorrect && theme === "dark",
										"text-correct-answer font-bold": ans.isCorrect && theme !== "dark",
										"text-correct-answer-theme-dark font-bold": ans.isCorrect && theme === "dark",
									}
								)}
							>
								{slideWithSortedAnswers.type === SLIDE_TYPE_REORDER ? (
									<div className="flex flex-shrink-0 w-4 h-5 mt-1">{i + 1}.</div>
								) : ans.isCorrect ? (
									<CheckIcon className="flex flex-shrink-0 w-4 h-5 mt-1" />
								) : (
									<div className="flex flex-shrink-0 w-4 h-5 mt-0" />
								)}
								<p className="leading-tight">{upperFirst(ans.text, lang) || ""}</p>
							</div>
						))}
					{slideWithSortedAnswers.type === SLIDE_TYPE_RANGE && (
						<div className="flex flex-row items-center gap-1 text-base font-medium leading-tight">
							<div className="opacity-50">{slideWithSortedAnswers.answers[1]?.text.split(",")[0]} —</div>
							<div
								className={tailwindCascade(
									"flex flex-row space-x-1 space-y-0 gap-1",
									"bg-correct-answer rounded-full text-white px-2.5 py-1.5 font-bold"
								)}
							>
								<CheckIcon className="w-4 h-5 mt-0.0 flex flex-shrink-0" />
								<span className="opacity-100">
									{slideWithSortedAnswers.answers[0].text.split(" ")[0]}
								</span>
							</div>
							<div className="opacity-50">— {slideWithSortedAnswers.answers[1]?.text.split(",")[1]}</div>
							<div className="opacity-50">{slideWithSortedAnswers.answers[0].text.split(" ")[1]}</div>
						</div>
					)}
					{slideWithSortedAnswers.type === SLIDE_TYPE_LOCATION && mapProps?.position && (
						<>
							{mapProps.name && (
								<div
									className={tailwindCascade(
										"flex flex-row space-x-2 space-y-1 mb-4",
										"leading-tight text-base font-medium",
										"text-correct-answer font-bold"
									)}
								>
									<CheckIcon className="flex flex-shrink-0 w-4 h-5 mt-1" />
									<p className="leading-tight">{mapProps.name}</p>
								</div>
							)}
							<img
								className="rounded-lg"
								alt=""
								src={
									"https://maps.googleapis.com/maps/api/staticmap?" +
									[
										`center=${mapProps.position.lat},${mapProps.position.lng}`,
										`zoom=${mapProps.zoom}`,
										"size=128x96",
										mapId ? `map_id=${mapId}` : mapTypeId ? `maptype=${mapTypeId}` : "",
										`key=${GOOGLE_MAPS_API_KEY}`,
									]
										.concat(
											mapProps.name
												? []
												: [`markers=${mapProps.position.lat},${mapProps.position.lng}`]
										)
										.join("&")
								}
							/>
						</>
					)}
					{noAnswer && <p className="font-roboto text-sm font-normal opacity-100">{trans("(No answer)")}</p>}
				</div>
			</div>
			{(isFinite(difficulty) || slideWithSortedAnswers.funFact) && (
				<div className="flex flex-col justify-end flex-grow w-full">
					<div
						className={tailwindCascade("relative w-full h-[1px] bg-black bg-opacity-10 mb-4", {
							"bg-white": theme === "dark",
						})}
					/>

					{isFinite(difficulty) && (
						<div
							className={tailwindCascade("flex flex-col gap-y-2 px-4 mb-4", {
								"filter blur-sm opacity-50 select-none": blurQuestion || blurAnswers,
							})}
						>
							<h3
								className="inline-block text-sm font-bold align-middle"
								data-nosnippet
								title={
									!blurQuestion && !blurAnswers
										? trans(
												"%d/%d",
												slideWithSortedAnswers.numCorrectlyAnswered,
												slideWithSortedAnswers.numCorrectlyAnswered +
													slideWithSortedAnswers.numIncorrectlyAnswered +
													slideWithSortedAnswers.numUnanswered
										  )
										: null
								}
							>
								<>
									{isEasy && (
										<>
											<img
												src="/images/icons/icon-label-easy.svg"
												alt={trans("Easy")}
												className="inline-block w-auto h-5"
											/>
											&nbsp;&nbsp;
										</>
									)}
									{isHard && (
										<>
											<img
												src="/images/icons/icon-label-hard.svg"
												alt={trans("Hard")}
												className="inline-block w-auto h-5"
											/>
											&nbsp;&nbsp;
										</>
									)}
									{trans("%d%% got this right ", Math.round(100 * (1 - difficulty)))}
								</>
							</h3>
						</div>
					)}

					{slideWithSortedAnswers.funFact && (
						<div
							className={tailwindCascade("text-sm font-normal leading-4 opacity-70 px-4 min-h-12", {
								"filter blur-sm opacity-50 select-none": blurQuestion || blurAnswers,
							})}
						>
							{slideWithSortedAnswers.funFact}
						</div>
					)}
				</div>
			)}
		</Wrapper>
	);
}
